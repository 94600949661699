import React from 'react';

const OutOfStock = () => {
  return (
    <div className='outofstock'>
      Out of stock.
    </div>
  )
}

export default OutOfStock;