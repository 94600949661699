import React, { useEffect } from "react";
import SaleStatistic from "../components/common/SaleStatistic";
import UserStatistic from "../components/common/UserStatistic";
import LifeTimeStatistic from "../components/common/LifeTimeStatistic";
import { generateDateRange } from "../../utils/generateDateRange";
import { useSelector, useDispatch } from "react-redux";
import { 
  loadOrderStatistic,
  loadSaleStatistic,
  loadLifetimeOrders,
  loadLifetimeSales,
  loadUserStatistic } from "../../store/adminSlice";
import '../styles/pages/dashboardpage.scss';

const DashboardPage = () => {

  const dispatch = useDispatch();
  const isOrderStatisticLoading = useSelector(state => state.admin.isOrderStatisticLoading);
  const isSaleStatisticLoading = useSelector(state => state.admin.isSaleStatisticLoading);
  const isLifetimeOrdersLoading = useSelector(state => state.admin.isLifetimeOrdersLoading);
  const isLifetimeSalesLoading = useSelector(state => state.admin.isLifetimeSalesLoading);

  const dateRange = generateDateRange(5);
  const startDate = dateRange.startDate;
  const lastDate = dateRange.lastDate;

  useEffect(() => {
    dispatch(loadOrderStatistic({startDate, lastDate}));
    dispatch(loadSaleStatistic({startDate, lastDate}));
    dispatch(loadLifetimeOrders());
    dispatch(loadLifetimeSales());
    dispatch(loadUserStatistic({startDate, lastDate}))
  }, [])

  return (
    <div className="dashboardpage">
      <div className="page-header">
        <h2>Dashboard</h2>
      </div>
      <div className="page-main-content">
        <div className="content-statistics-lifetime">
          <div className="sale-user-statistic-wrapper">
            {isOrderStatisticLoading || isSaleStatisticLoading ? '' : <SaleStatistic />}
            <UserStatistic />
          </div>
          <div className="lifetime-statistic-wrapper">
            {isLifetimeOrdersLoading || isLifetimeSalesLoading ? '' : <LifeTimeStatistic />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardPage;