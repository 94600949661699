import React from "react";
import Chart from "react-apexcharts"
import { useSelector } from "react-redux";

const SalesChart = (props) => {

  const orderStatistic = useSelector(state => state.admin.orderStatistic);
  const saleStatistic = useSelector(state => state.admin.saleStatistic);

  const generateOrdersDateArray = () => {
    const dates = [];
    orderStatistic.map(row => {
      dates.push(row.date)
    })
    return dates;
  }

  const generateOrdersDataArray = () => {
    const data = [];
    orderStatistic.map(row => {
      data.push(row.count)
    })
    return data;
  }

  const generateSaleDataArray = () => {
    const data = [];
    saleStatistic.map(row => {
      data.push(row.total)
    })
    return data;
  }
  
  const ordersDateArray = generateOrdersDateArray();
  const ordersDataArray = generateOrdersDataArray();
  const saleDataArray = generateSaleDataArray();

  const options = {
    chart: {
      type: 'area',
      zoom: {
        enabled: false
      },
      width: '100%',
      height: '320px',
      fontFamily: 'Lexend, sans-serif',
      toolbar: {
        show: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      categories: ordersDateArray,
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy'
      },
    },
    yaxis: {
      opposite: true

    },
    legend: {
      horizontalAlign: 'left'
    },
    colors: ['#17AF26', '#05422C'],
  }

  const series = [{
    name: 'Orders',
    data: ordersDataArray,
  }, {
    name: 'Sale $',
    data: saleDataArray,
  }]

  return (
    <Chart 
      options={options} 
      series={series} 
      type="area" 
    />
  )
}

export default SalesChart;