import React from "react";
import Chart from "react-apexcharts"
import { useSelector } from "react-redux";

const UsersChart = () => {

  const userStatistic = useSelector(state => state.admin.userStatistic);

  const generateDateArray = () => {
    const dates = [];
    userStatistic.map(row => {
      dates.push(row.date)
    })
    return dates;
  }

  const generateUserDataArray = () => {
    const data = [];
    userStatistic.map(row => {
      data.push(row.total)
    })
    return data;
  }

  const usersDateArray = generateDateArray();
  const usersDataArray = generateUserDataArray();

  const options = {
    chart: {
      type: 'area',
      zoom: {
        enabled: false
      },
      width: '100%',
      height: '320px',
      fontFamily: 'Lexend, sans-serif',
      toolbar: {
        show: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      categories: usersDateArray,
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy'
      },
    },
    yaxis: {
      opposite: true
    },
    legend: {
      horizontalAlign: 'left'
    },
    colors: ['#F2BC1B'],
  }

  const series = [{
    name: 'Users',
    data: usersDataArray,
  }]

  return (
    <Chart 
      options={options} 
      series={series} 
      type="area" 
    />
  )
}

export default UsersChart;